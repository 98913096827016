<template>
  <div class="channel-page">
    <div class="channel">
      <div class="g-container">
        <div class="channel-details">
          <div class="channel-title" @click="onGoBack">
            <arrow-back v-if="isMobile" />
            <p class="title">{{ title }}</p>
          </div>

          <div class="channel-wrap" :class="{ 'channel-wrap--full': !epgs || !epgs.length }">
            <div class="channel-detail">
              <video-player
                v-if="track"
                :poster="track.image"
                :video-source="track.location"
                :preroll="preroll"
              />
              <div class="text-icon" v-if="activeEpg">
                <Clock />
                <p class="text">{{ moment(activeEpg.datetimeFrom).format('HH:mm') }} - {{ moment(activeEpg.datetimeTo).format('HH:mm') }}</p>
              </div>
              <div class="av-counts">
                <p class="av-count" v-if="activeEpg">{{ activeEpg.title }}</p>
              </div>
              <div class="channel-av">
<!--                <p class="channel-section-text">Реклама</p>-->
<!--                <Adsense-->
<!--                  class="channel-av__adsense"-->
<!--                  data-ad-client="ca-pub-5025320822350726"-->
<!--                  data-ad-slot="1234567890"-->
<!--                  data-ad-format="auto"-->
<!--                  data-full-width-responsive="true"-->
<!--                />-->
                <div id="yandex_rtb_R-A-1459810-2"></div>
              </div>
            </div>

            <div class="channel-items" ref="channels" v-if="epgs && epgs.length">
              <div
                class="channel-texts now"
                :class="{
                  'now': epg.isPast,
                  'active': epg.isActive,
                  'after': epg.isFuture
                }"
                :key="`${index}-${epg.title}-${epg.time_from}`"
                v-for="(epg, index) in epgs"
                :ref="`${epg.title}-${epg.time_from}-${epg.time_to}`"
              >
                <p class="texts">{{ moment(epg.datetimeFrom).format('HH:mm') }}</p>
                <p class="channel-text">{{ epg.title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Clock from '@/components/icons/Clock'
import ArrowBack from '@/components/icons/ArrowBack'

const parseDate = date => {
  if (date) {
    return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}T${date.slice(8, 10)}:${date.slice(10, 12)}:${date.slice(12, 14)}Z`
  }
}

export default {
  name: 'Channel',

  components: {
    ArrowBack,
    Clock,
    VideoPlayer: () => import('@/components/VideoPlayer')
  },

  props: {
    channelList: {
      type: Object,
      required: true
    },
    trackList: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      updateEveryMinutes: 3,
      interval: null,
      updateCount: 0,
      epgs: null,
      isMobile: window.innerWidth <= 1000
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.scrollToActive()
    })

    this.interval = setInterval(() => {
      this.updateCount += 1
    }, this.updateEveryMinutes * 1000 * 60)

    window.addEventListener('resize', this.onResize)
    this.onResize()

    if (window.yaContextCb && window.Ya) {
      window.yaContextCb.push(() => {
        window.Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-1459810-2',
          blockId: 'R-A-1459810-2',
          pageNumber: Math.round(Math.random() * 10) * 100,
          async: true
        })
      })
    }
  },

  watch: {
    updateCount: {
      immediate: true,
      handler () {
        this.setEpgs()
      }
    }
  },

  computed: {
    track () {
      return this.trackList?.trackList?.track?.find(c => c.id?.toString() === this.$route.query.channel?.toString())
    },

    preroll () {
      return this.track.PreRoll &&
        typeof this.track.PreRoll === 'string' &&
        this.track.PreRoll.startsWith('http') ? this.track.PreRoll : null
    },

    channel () {
      return this.channelList?.channels?.find(c => c.channel_name === this.track?.title)
    },

    title () {
      return this.track?.title
    },

    activeEpg () {
      return this.epgs?.find(epg => epg.isActive)
    }
  },

  methods: {
    moment,

    scrollToActive () {
      const activeEpg = this.epgs?.find(epg => epg.isActive)
      if (this.activeEpg) {
        let activeEpgEl = this.$refs[`${activeEpg.title}-${activeEpg.time_from}-${activeEpg.time_to}`]
        activeEpgEl = activeEpgEl[0] ? activeEpgEl[0] : activeEpgEl
        if (activeEpgEl) {
          this.$refs.channels.scroll({
            top: activeEpgEl.offsetTop - this.$refs.channels.offsetHeight / 2,
            behavior: 'smooth'
          })
        }
      }
    },

    setEpgs () {
      this.epgs = this.channelList?.epg
        ?.filter(e => e.channel_id === this.channel?.channel_id)
        .map(e => {
          const datetimeFrom = parseDate(e.time_from)
          const datetimeTo = parseDate(e.time_to)

          const isPast = moment().isAfter(datetimeTo)
          const isFuture = moment().isBefore(datetimeFrom)

          return {
            ...e,
            datetimeFrom,
            datetimeTo,
            isPast,
            isFuture,
            isActive: !isPast && !isFuture
          }
        })

      this.$nextTick(() => {
        this.scrollToActive()
      })
    },

    onResize () {
      this.isMobile = window.innerWidth <= 1000
    },

    onGoBack () {
      if (this.isMobile) {
        try {
          this.$router.push('/')
        } catch {}
      }
    }
  },

  beforeDestroy () {
    clearInterval(this.interval)
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
