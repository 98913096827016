<template>
  <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9669 22.0875C11.5675 22.6581 12.5169 22.6337 13.0875 22.0331C13.6581 21.4325 13.6337 20.4831 13.0331 19.9125L10.9669 22.0875ZM2 11.5L0.966877 10.4125C0.668781 10.6957 0.500001 11.0888 0.500001 11.5C0.500001 11.9112 0.668781 12.3043 0.966877 12.5875L2 11.5ZM13.0331 3.0875C13.6337 2.51692 13.6581 1.56749 13.0875 0.966875C12.5169 0.366266 11.5675 0.341923 10.9669 0.9125L13.0331 3.0875ZM13.0331 19.9125L3.03312 10.4125L0.966877 12.5875L10.9669 22.0875L13.0331 19.9125ZM3.03312 12.5875L13.0331 3.0875L10.9669 0.9125L0.966877 10.4125L3.03312 12.5875Z" fill="#3F3F3F"/>
  </svg>
</template>

<script>
export default {
  name: 'ArrowBack'
}
</script>
